import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    iphone6: true; // adds the `tablet` breakpoint
  }
}

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      iphone6: 700,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;

// down(sm) means @media (max-width: 959.95px)
// up(sm) means @media (min-width: 600px)
