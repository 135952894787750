import { useEffect, useRef, useState } from 'react';
import { notifyError } from '../../../shared/Notifications';
import useGameStore from '../../../store/useGameStore';
import gameDataAPI from '../../api/gameDataAPI';
import LoaderCircular from '../../components/shared/ui/Loader';

import { TScore } from '../../interfaces/userInterface';

import { getDeviceInfo } from '../../../hooks/useDeviceInfo2';
import PdfView from '../../components/pdfView/PdfView';

import { getGameLinkType, getGameUrl } from './utils/gameUtils';

import IPhoneOrientationMessage from '../../pages/pages-ui/IPhoneOrientationMessage';
import VimeoPlayer from '../../components/videos/VimeoPlayer';
import {
  getScreenOrientation,
  lockOrientation,
  makeFullScreen,
} from './utils/orientationAndFullscreen';
import {
  soundsInfo,
  muteAllAudio,
  pausePlayingAudios,
  playPausedAudios,
  stopAllAudio,
  stopAudio,
  unMuteAllAudio,
} from './utils/soundUtils';
import {
  createIframeElements,
  makeIframeVisible,
  sendGameStartMessage,
} from './utils/iframeUtils';
import PasswordGame from '../../components/passwordGame/PasswordGame';
import VRminiGame from '../../components/VRminiGame/VRminiGame';
import GameOverPage from '../../components/gameOverPage/GameOverPage';
import DownloadFile from '../../components/DownloadFile/DownloadFile';

export default function IframeGameMaster() {
  const userData = useGameStore((state) => state.userData);
  
  const setUserData =  useGameStore(state => state.setUserData);
  const { playerDetails } = userData;
  const masterGames = userData.gameDetails.games;
  // const masterGames = MockData;
  const gameLoaded = useRef(new Map<string, boolean>());
  const token = useGameStore((state) => state.gameToken);
  const setScore = useGameStore((state) => state.setScore);
  const [isLoading, setIsLoading] = useState(false);
  const [screenOrientation, setScreenOrientation] = useState<
    'portrait' | 'landscape'
  >(getScreenOrientation());

  const osName = getDeviceInfo();
  const isMuted = useRef({ game: false, reactApp: false });
  const [isGameOver, setIsGameOver] = useState(false);
  const lastSoundPlayedWithLoopTrue = useRef('');

  const characterNames = useRef({
    boy: playerDetails.characterNames?.boy || 'Robin-1',
    girl: playerDetails.characterNames?.girl || 'Sara-1',
  });

  // playCorrectSound();
  const [currentGameIndex, setCurrentGameIndex] = useState(
    playerDetails.currentBlock.tileNumber - 1
  );
  const gameScores = useGameStore((state) => state.scoreData);

  const gameScoresRef = useRef(gameScores);

  let currentGameId = masterGames[currentGameIndex].gameId;

  const iframeContainer = useRef<HTMLDivElement>(null);
  const nextIframeRef = useRef<HTMLDivElement>(null);

  const isPortraitOnMobile = () => {
    // const device = getDeviceInfo();
    // if (device !== 'desktop' && screenOrientation === 'portrait') {
    //   return true;
    // }
    // return false;
    return osName !== 'desktop' && screenOrientation === 'portrait';
  };

  // this will create a new iFrame if the next component is not a video component
  const createNewIframe = (nextGameIndex) => {
    const nextGameData = masterGames[nextGameIndex];

    // if game type is videos then we don't want to create an iframe element
    if (
      nextGameData.gameType === 'Videos' ||
      nextGameData.gameType === 'Pdf' ||
      nextGameData.gameType === 'PasswordGames' ||
      nextGameData.gameType === 'VRGame' ||
      nextGameData.gameType === 'File'
    ) {
      nextIframeRef.current = null;
      return;
    }
    console.log('Creating next Iframe with data', {
      gameId: masterGames[nextGameIndex].gameId,
      type: masterGames[nextGameIndex].gameType,
    });

    if (masterGames[nextGameIndex].gameType === 'MiniGames') {
      gameDataAPI
        .getMiniGameById(masterGames[nextGameIndex].gameId)
        .then((gameData) => {
          const { mobileUrl, webUrl } = gameData;
          let nextIframeUrl;
          if (mobileUrl && webUrl) {
            if (
              webUrl === 'https://games.talentlitmus.com/endlessrunnerweb/' ||
              webUrl === 'https://games.talentlitmus.com/endlessrunnerintroweb/'
            ) {
              nextIframeUrl =
                getDeviceInfo() === 'desktop' ? webUrl : mobileUrl;
              // if we have low resolution then we will run the that specific build
              if (
                nextIframeUrl ===
                  'https://games.talentlitmus.com/endlessrunnerintroweb/' &&
                window.innerHeight < 700
              ) {
                nextIframeUrl =
                  'https://games.talentlitmus.com/enlessrunnerintrolowres/';
              }

              nextIframeUrl += `?myParam=${gameData._id}`;
            } else if (webUrl === 'https://vr.game') {
              // we will update the next game type to VR Game
              userData.gameDetails.games[nextGameIndex].gameType = 'VRGame';
              setUserData({ ...userData });
              nextIframeRef.current = null;
              return;
            } else {
              nextIframeUrl = getGameLinkType() === 'web' ? webUrl : mobileUrl;
              nextIframeUrl += `?myParam=${gameData._id}`;
            }
          } else {
            nextIframeUrl = getGameUrl(
              gameData._id,
              masterGames[nextGameIndex].gameType,
              osName,
              gameData.name
            );
          }

          const { container, ifrm } = createIframeElements(
            nextIframeUrl,
            masterGames[nextGameIndex].gameId
          );

          iframeContainer.current.appendChild(container);

          container.appendChild(ifrm);
          nextIframeRef.current = container;
          ifrm.onload = () => {
            console.log('Next iframe loaded successfully', nextIframeRef);
            nextIframeRef.current = container;

            // alert('nextiframeLoaded ' + nextIframeUrl);
          };
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let nextIframeUrl = getGameUrl(
        masterGames[nextGameIndex].gameId,
        masterGames[nextGameIndex].gameType,
        osName
      );

      const { container, ifrm } = createIframeElements(
        nextIframeUrl,
        masterGames[nextGameIndex].gameId
      );

      iframeContainer.current.appendChild(container);

      container.appendChild(ifrm);
      nextIframeRef.current = container;
      ifrm.onload = () => {
        console.log('Next iframe loaded successfully', nextIframeRef);
        nextIframeRef.current = container;

        // alert('nextiframeLoaded ' + nextIframeUrl);
      };
    }
  };

  const checkAndCreateNewIframe = (currentGameIndex, masterGames) => {
    if (currentGameIndex < masterGames.length - 1) {
      createNewIframe(currentGameIndex + 1);
    }
  };

  const updateAndGetScore = (scoreData: TScore) => {
    // see if the current section already present in gameScoreRef

    if (!scoreData.actualScore || !scoreData.targetScore) {
      return gameScoresRef.current;
    }

    const sectionIndex = gameScoresRef.current.findIndex(
      (el) => el.sectionName === scoreData.sectionName
    );
    if (sectionIndex === -1) {
      // in section not present the the array then we will

      gameScoresRef.current.push(scoreData);
    } else {
      // if the section already present in the array, the we already have the index and we added the current
      // score to that section score
      gameScoresRef.current[sectionIndex].actualScore += scoreData.actualScore;
      gameScoresRef.current[sectionIndex].targetScore += scoreData.targetScore;
    }

    return gameScoresRef.current;
  };

  // if updatedScore is not provided then we will take current Game Score array and update the score
  const updateScore = async (
    updatedScores: TScore[] = gameScoresRef.current,
    characterNames: any
  ) => {
    try {
      setScore(updatedScores);

      let uploadResponse: any = {
        token,
        orientationScores: updatedScores,
        currentBlock: {
          completed: false,
          // next index which is about to start is currentGameIndex + 1, this is not completed in count is currentGameIndex + 1 + 1
          tileNumber: currentGameIndex + 2,
        },
        gameStatus:
          currentGameIndex + 1 < masterGames.length
            ? 'in-progress'
            : 'completed',
      };
      if (characterNames.boy || characterNames.girl) {
        uploadResponse = {
          ...uploadResponse,
          characterNames: { ...characterNames },
        };
      }
      const response = await gameDataAPI.updateProgress(uploadResponse);

      console.log('Update Scores response', response);

      return response;
    } catch (error) {
      console.log(error);
      notifyError('Error updating game data! Please try again');
      throw error;
    }
  };

  // this will only render the iframe if it is present in the DOM;
  const renderNextIframe = () => {
    const parentOfNext = nextIframeRef.current;

    if (parentOfNext) {
      // make the iframe visible

      makeIframeVisible(parentOfNext, masterGames[currentGameIndex + 1].gameId);

      // render if the game id exist in the map set
      // game is ready to be launched
      if (gameLoaded.current.has(masterGames[currentGameIndex + 1].gameId)) {
        console.log(
          'Start game event fired for: ',
          masterGames[currentGameIndex + 1].gameId
        );
        sendGameStartMessage(parentOfNext, isMuted.current.game, {
          cohortId: playerDetails.cohortId,
          email: playerDetails.email,
          scoreData: gameScoresRef.current,
          gameId: masterGames[currentGameIndex + 1].gameId,
          characterNames: { ...characterNames.current },
        });
        //! Temp code to be removed later...we will fire the gameComplete event manually after 2 min
        const nextgame = masterGames[currentGameIndex + 1];
        if (nextgame.gameType === 'MiniGames') {
          // setTimeout(() => {
          //   window.top.postMessage(
          //     JSON.stringify({
          //       actualscore: 0,
          //       targetScore: 0,
          //       gameId: nextgame.gameId,
          //       eventType: 'GameCompleted',
          //     }),
          //     '*'
          //   );
          // }, 60 * 1000);
        }
      } else {
        console.log(
          'Start game event fired (interval) for: ',
          masterGames[currentGameIndex + 1].gameId
        );
        // if game id is not there then we will check every second if the game gameid is fetched yet
        // if it is there then we will clean the interval
        //TODO: find a better alternative of setInterval
        if (
          masterGames[currentGameIndex + 1].gameType === 'Videos' ||
          masterGames[currentGameIndex + 1].gameType === 'Pdf' ||
          masterGames[currentGameIndex + 1].gameType === 'PasswordGames' ||
          masterGames[currentGameIndex + 1].gameType === 'File' ||
          masterGames[currentGameIndex + 1].gameType === 'VRGame'
        ) {
          // we don't want to set interval for these game
          return;
        }

        const intervalId = setInterval(() => {
          if (
            gameLoaded.current.has(masterGames[currentGameIndex + 1].gameId)
          ) {
            sendGameStartMessage(parentOfNext, isMuted.current.game, {
              cohortId: playerDetails.cohortId,
              email: playerDetails.email,
              scoreData: gameScoresRef.current,
              gameId: masterGames[currentGameIndex + 1].gameId,
              characterNames: { ...characterNames.current },
            });
            clearInterval(intervalId);
          }
        }, 500);
      }
    }
  };

  const handleSound = (sound) => {
    if (sound.status === 'play') {
      const audioElement = document.querySelector(
        `audio[src='${sound.url}']`
      ) as HTMLAudioElement;
      if (!audioElement) return;
      audioElement.volume = sound.volume;
      audioElement.loop = sound.loop;
      // we can set the current time to 0 to play the sound from the start
      // audioElement.currentTime = 0.0;

      soundsInfo[sound.url] = { currentlyPlaying: true };
      // we don't want to play music if sound are muted
      // if (isMuted.current.game || isMuted.current.reactApp) {
      //   return;
      // }

      if (osName === 'iOS') {
        // for case if iOS due to sound issue we keep muted as false initially
        // and after some timeout we will change to current state
        audioElement.muted = true;
        setTimeout(() => {
          audioElement.muted = isMuted.current.game || isMuted.current.reactApp;
        }, 10);
      } else {
        // we will play the music but keep it muted based on the game condition
        audioElement.muted = isMuted.current.game || isMuted.current.reactApp;
      }

      //we will store the last playing bg music
      // we use loop as true to know whether music is bg music
      // this will ensure there will only be one sound played with loop = true at a time
      if (sound.loop) {
        // if the sound url is already playing then we
        // will not play this sound again
        if (
          lastSoundPlayedWithLoopTrue.current &&
          lastSoundPlayedWithLoopTrue.current !== sound.url
        ) {
          // if current url is different from the last playing url then
          // we will stop the last playing music
          const audioElement = document.querySelector(
            `audio[src='${lastSoundPlayedWithLoopTrue.current}']`
          ) as HTMLAudioElement;
          stopAudio(audioElement);
        }
        // update the lastSoundPlayedWithLoopTrue url
        lastSoundPlayedWithLoopTrue.current = sound.url;
      } else {
        audioElement.currentTime = 0.0;
      }

      audioElement
        .play()
        .then(() => {})
        .catch((e) => {
          console.log(e.message);
        });
    } else if (sound.status === 'stop') {
      const audioElement = document.querySelector(
        `audio[src='${sound.url}']`
      ) as HTMLAudioElement;
      // audioElement.volume = sound.volume;
      if (!audioElement) return;
      audioElement.loop = false;

      soundsInfo[sound.url] = { currentlyPlaying: false };

      // audioElement.muted = true;
      // audioElement.pause();
      stopAudio(audioElement);
    } else if (sound.status === 'muteall') {
      muteAllAudio();
      isMuted.current.game = true;
    } else if (sound.status === 'unmuteall') {
      unMuteAllAudio();

      isMuted.current.game = false;
    } else if (sound.status === 'stopall') {
      // we will clear the last played bg also
      lastSoundPlayedWithLoopTrue.current = '';
      stopAllAudio();
    }
  };

  const handleNextGameLoad = (data) => {
    gameLoaded.current.set(data.gameId, true);

    // if this is the first game, then we run the game as soon as we get the message that game is loaded
    if (
      currentGameIndex === playerDetails.currentBlock.tileNumber - 1 &&
      data.gameId === currentGameId
    ) {
      // console.log(
      //   'Start game event fired (handleNextGameLoad) for: ',
      //   masterGames[currentGameIndex + 1].gameId
      // );

      sendGameStartMessage(
        iframeContainer.current.firstElementChild as any,
        isMuted.current.game,
        {
          cohortId: playerDetails.cohortId,
          email: playerDetails.email,
          scoreData: gameScores,
          gameId: currentGameId,
          characterNames: { ...characterNames.current },
        },
        true
      );
    }
  };

  const handleIframeGameCompleted = async (data) => {
    console.log('GameCompleted Event', data);
    // when the game is complete

    //Step 1: Remove the current Iframe from the dom
    const iframeElement = iframeContainer.current
      .firstChild as HTMLIFrameElement;
    iframeElement.src = 'about:blank';
    // iframeElement.remove();
    iframeContainer.current.removeChild(iframeElement);

    //we will stop all sound when the game is completed
    stopAllAudio();

    // setIsLoading(true);
    // update the score
    //TODO: we might need loader here
    // we will also updating the gameScoresRef in the function. Ideally it should be done after score data is
    // updated in the api but we will update it here before that
    // even if api fails we still have the updated score and we can update it in the next call;
    let updatedScores = updateAndGetScore({
      actualScore: data.actualscore,
      targetScore: data.targetScore,
      sectionName: masterGames[currentGameIndex].sectionName,
    });

    const { boy, girl } = data;

    if (boy || girl) {
      characterNames.current.boy = boy;
      characterNames.current.girl = girl;
    }

    await updateScore(updatedScores, { boy: boy, girl: girl });
    // .then((res) => {
    //   console.log('Score updated successfully');
    // })
    // .catch((err) => {
    //   console.log('Error Updating score');
    //   console.error(err.message);
    // });

    // setIsLoading(false);

    // if this is the last game index then game is over
    if (currentGameIndex + 1 >= masterGames.length) {
      setIsGameOver(true);
      return;
    }

    // Step 2: If there is next iframe in the queue
    //then make the next queued iframe visible and send the start game message
    // if next iframe data is null then this function will not render the iframe in DOM
    // and the video component will be loaded from the react
    renderNextIframe();

    // Step 3: Create the next iframe into the dom if the next to next is not a video component
    // so game at current index is removed, game at currentIndex +1 is already there so we will load
    // game at current index + 2
    if (currentGameIndex + 2 < masterGames.length)
      createNewIframe(currentGameIndex + 2);

    // Step 4: Update the current game index
    setCurrentGameIndex((current) => current + 1);
  };

  /*************************Iframe Event Handler****************************************************** */

  const handleIframeEvents = async (event) => {
    // there will be two type of event that we are listing for
    //1. 'GameLoaded' and 2. 'GameCompleted'

    let data;

    if (typeof event.data === 'string') {
      data = JSON.parse(event.data);
      // console.log(data.gameId, 'data.gameId 453');
      // console.log(data.eventType, 'data.eventType 454');

      if (data.eventType === 'Sound') {
        handleSound(data);
        return;
      }

      if (data.eventType === 'GameLoaded') {
        // storing the gameId in the map set whenever a game is loaded
        handleNextGameLoad(data);
        return;
      }
      if (data.eventType === 'GameCompleted' && data.gameId === currentGameId) {
        handleIframeGameCompleted(data);
        return;
      }
    }
  };

  const onOtherGamesComplete = () => {
    // await updateScore();
    // .then((res) => {
    //   console.log('Score updated successfully');
    // })
    // .catch((err) => {
    //   console.log('Error Updating score');
    //   console.error(err.message);
    // });

    // check if the next game index is greater then the master game length
    // then game is over
    if (currentGameIndex + 1 >= masterGames.length) {
      // TODO we want to display game complete screen
      setIsGameOver(true);
      return;
    }

    // setIsLoading(true);

    // setIsLoading(false);

    renderNextIframe();

    // otherwise go to step 3
    // Step 3: Create the next iframe into the dom
    // so game at current index is removed, game at currentIndex +1 is already there so we will load
    // game at current index + 2
    if (currentGameIndex + 2 < masterGames.length)
      createNewIframe(currentGameIndex + 2);

    setCurrentGameIndex((current) => current + 1);
  };

  useEffect(() => {
    const createAndRenderGame = (gameData) => {
      const { mobileUrl, webUrl } = gameData;
      let nextIframeUrl;
      if (mobileUrl && webUrl) {
        if (
          webUrl === 'https://games.talentlitmus.com/endlessrunnerweb/' ||
          webUrl === 'https://games.talentlitmus.com/endlessrunnerintroweb/'
        ) {
          nextIframeUrl = getDeviceInfo() === 'desktop' ? webUrl : mobileUrl;

          if (
            nextIframeUrl ===
              'https://games.talentlitmus.com/endlessrunnerintroweb/' &&
            window.innerHeight < 700
          ) {
            nextIframeUrl =
              'https://games.talentlitmus.com/enlessrunnerintrolowres/';
          }

          nextIframeUrl += `?myParam=${gameData._id}`;
        } else if (webUrl === 'https://vr.game') {
          // we it is a VR game we update the game type
          // and return without creating a new Iframe
          userData.gameDetails.games[currentGameIndex].gameType = 'VRGame';
          setUserData({ ...userData });
          return;
        } else {
          nextIframeUrl = getGameLinkType() === 'web' ? webUrl : mobileUrl;
          nextIframeUrl += `?myParam=${gameData._id}`;
        }
      } else {
        nextIframeUrl = getGameUrl(
          gameData._id,
          masterGames[currentGameIndex].gameType,
          osName,
          gameData.name || ''
        );
      }

      const { container, ifrm } = createIframeElements(
        nextIframeUrl,
        masterGames[currentGameIndex].gameId
      );

      iframeContainer.current.appendChild(container);

      container.appendChild(ifrm);

      makeIframeVisible(container, null);

      // makeIframeVisible(container);
    };

    // alert(deviceInfo.os.name);
    // when page load for the first time
    //  if current game is not videos then we create the iframe and load it into dom

    if (
      masterGames[currentGameIndex].gameType === 'Videos' ||
      masterGames[currentGameIndex].gameType === 'Pdf' ||
      masterGames[currentGameIndex].gameType === 'PasswordGames' ||
      masterGames[currentGameIndex].gameType === 'File' ||
      masterGames[currentGameIndex].gameType === 'VRGame'
    ) {
      // do nothing for them, just check if we can create iFrame not next games
      checkAndCreateNewIframe(currentGameIndex, masterGames);
      return;
    }

    if (masterGames[currentGameIndex].gameType === 'MiniGames') {
      // fetch the gameName from the api then render the iframe
      gameDataAPI
        .getMiniGameById(masterGames[currentGameIndex].gameId)
        .then((gameData) => {
          if (!gameData) {
            notifyError(
              'Something went wrong, in loading the game. Please refresh and try again'
            );
            return;
          }

          createAndRenderGame(gameData);

          // create the next iframe element only if current index is not the last
          checkAndCreateNewIframe(currentGameIndex, masterGames);
        })
        .catch((err) => {
          console.log(err);
          notifyError(
            'Something went wrong, in loading the game. Please refresh and try again'
          );
        });

      return;
    }

    createAndRenderGame({
      _id: masterGames[currentGameIndex].gameId,
      name: '',
    });

    checkAndCreateNewIframe(currentGameIndex, masterGames);
  }, []);

  useEffect(() => {
    //  makeFullScreen();
    //  lockOrientation();

    const setFullScreen = async () => {
      // we only want full screen functionality for android
      if (osName === 'Android') {
        await makeFullScreen();
        await lockOrientation();
      }
    };

    setFullScreen().catch((err) => {
      console.log(err);
    });

    // if device in portrait on mobile in the start then we will mute the audio for the react app
    // we only do this for iOs
    if (isPortraitOnMobile() && osName === 'iOS') {
      isMuted.current.reactApp = true;
    }

    document.addEventListener('visibilitychange', async (e) => {
      // if document is not visible then we will mute all the audios
      if (document.visibilityState === 'hidden') {
        pausePlayingAudios();

        // muteAllAudio();
        isMuted.current.reactApp = true;
        // if on visiblity change document is visible now and os is not iOS then we will unmute all
        // if the angle is != 0 if the os is window or not

        return;
      }

      // we will set an interval which will run every one second to check if user has touch screen and
      // request for full screen, when successful we will clear the interval
      const intervalId = setInterval(async () => {
        try {
          await setFullScreen();
          clearInterval(intervalId);
        } catch (error: any) {
          // alert('error fullscreen');
          console.log(error.message);
        }
      }, 2000);
      // for android we will play all the paused audios
      if (osName !== 'iOS' || getScreenOrientation() === 'landscape') {
        // audio is unmuted from the game then only we will unmute all the audio for the game

        playPausedAudios(isMuted.current.game);

        isMuted.current.reactApp = false;
      }
      //else if os is not windows then only if screen angle is not 0 then we will unmute all
    });

    window.addEventListener('orientationchange', () => {
      // we only want orientation change functionality for iOS
      if (osName !== 'iOS') {
        return;
      }

      const orientation = getScreenOrientation();
      if (orientation === 'portrait') {
        isMuted.current.reactApp = true;
        window.scrollTo(0, 0);
        pausePlayingAudios();
      } else {
        isMuted.current.reactApp = false;
        playPausedAudios(isMuted.current.game);
      }
      setScreenOrientation(getScreenOrientation());
    });
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleIframeEvents);

    return () => {
      window.removeEventListener('message', handleIframeEvents);
    };
  }, [currentGameId]);

  // for scroll issue in game before pdf
  // only add scroll to the doc body in the gameType is Pdf otherwise not
  useEffect(() => {
    if (masterGames[currentGameIndex].gameType === 'Pdf') {
      document.body.style.overflowY = 'scroll';
    } else {
      document.body.style.overflowY = 'initial';
    }
  });

  const getPdfGameid = () => {
    if (masterGames[currentGameIndex].gameType === 'Pdf') {
      return masterGames[currentGameIndex].gameId;
    } else if (
      currentGameIndex + 1 < masterGames.length &&
      masterGames[currentGameIndex + 1].gameType === 'Pdf'
    ) {
      return masterGames[currentGameIndex + 1].gameId;
    }
  };

  if (isGameOver) {
    return userData.gameDetails.companyId.name==="Al Shirawi" ? 
    <GameOverPage/> :<h1 style={{ color: 'white' }}>Game Completed Successfully</h1>;
  }

  const shouldDisplay = () => {
    if (masterGames[currentGameIndex].gameType === 'Pdf') {
      if (osName === 'iOS' && getScreenOrientation() === 'portrait') {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {osName === 'iOS' && <IPhoneOrientationMessage />}
      <div
        ref={iframeContainer}
        id="iframeref"
        className={osName === 'iOS' ? 'iosOrientation' : ''}
      >
        {/* Iframe games will be rendered inside this container */}
      </div>

      {isLoading && <LoaderCircular />}
      {/* For videos */}
      {masterGames[currentGameIndex].gameType === 'Videos' && (
        <div>
          <VimeoPlayer
            key={masterGames[currentGameIndex].gameId}
            osName={osName}
            gameId={masterGames[currentGameIndex].gameId}
            onComplete={onOtherGamesComplete}
          />
        </div>
      )}

      {masterGames[currentGameIndex].gameType === 'PasswordGames' && (
        <div>
          <PasswordGame
            gameId={masterGames[currentGameIndex].gameId}
            onCompleted={onOtherGamesComplete}
          />
        </div>
      )}

      {masterGames[currentGameIndex].gameType === 'VRGame' && (
        <div>
          <VRminiGame onCompleted={onOtherGamesComplete} />
        </div>
      )}

      {/* For file element */}
      {masterGames[currentGameIndex].gameType === 'File' && (
        <div>
          <DownloadFile
            gameId={masterGames[currentGameIndex].gameId}
            onCompleted={onOtherGamesComplete}
          />
        </div>
      )}

      {/* For Pdf */}
      {(masterGames[currentGameIndex].gameType === 'Pdf' ||
        (currentGameIndex + 1 < masterGames.length &&
          masterGames[currentGameIndex + 1].gameType === 'Pdf')) && (
        <div
          style={{
            display: shouldDisplay() ? 'block' : 'none',
          }}
        >
          <PdfView gameId={getPdfGameid()} onComplete={onOtherGamesComplete} />
        </div>
      )}
    </div>
  );
}
