import React, { useEffect } from 'react';
import LinkedInLogo from '../../../assets/images/640px-LinkedIn_logo_initials.png';
import TwitterLogo from '../../../assets/images/1200px-Twitter_bird_logo_2012.png';
import FacebookLogo from '../../../assets/images/1024px-Facebook_Logo_(2019).png';
import WebLogo from '../../../assets/images/logo-website-website-logo-png-transparent-background-background-15.png';
import CompanyLogo from '../../../assets/images/al-shariwi.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  '.container': {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      marginTop: '60px',

      width: '1030px',
      height: '583px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  '.Al-shariwi-img': { width: '60%' },
  '.connect-text': { fontSize: '26px', color: 'navy' },
  '.icons': {
    marginTop: '35px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  '.logo': {
    width: '70px',
    [theme.breakpoints.up('md')]: { width: '128px' },
  },
  'a-tag': {
    color: 'unset',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const GameOverPage = () => {
  const classes = useStyles();

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
    document.body.style.display = 'flex';
    document.body.style.justifyContent = 'center';
    document.body.style.alignItems = 'center';
    document.body.style.height = 'unset';

    return () => {
      document.body.style.backgroundColor = 'black';
    };
  }, []);

  return (
    <div className={classes['.container']}>
      <img
        className={classes['.Al-shariwi-img']}
        src={CompanyLogo}
        alt="Company Name"
      />
      <div>
        <p className={classes['.connect-text']}>Connect with us to know more</p>
      </div>
      <div className={classes['.icons']}>
        <a
          className={classes['a-tag']}
          target="_blank"
          href="https://www.facebook.com/oicllc/"  rel="noreferrer"
        >
          <img className={classes['.logo']} src={FacebookLogo} alt="Facebook" />
        </a>
        <a
          className={classes['a-tag']}
          target="_blank"  rel="noreferrer"
          href="https://www.linkedin.com/company/al-shirawi-group-of-companies-engineering-service-group-/mycompany/"
        >
          <img
            className={classes['.logo']}
            src={LinkedInLogo}
            alt="Linkedin logo"
          />
        </a>
        <a
          className={classes['a-tag']}
          target="_blank"
          href="https://twitter.com/AlShirawiGroup"
        >
          <img
            className={classes['.logo']}
            src={TwitterLogo}
            alt="Twitter Logo"
          />
        </a>
        <a
          className={classes['a-tag']}
          target="_blank"
          href="https://www.alshirawi.com/"
        >
          <img className={classes['.logo']} src={WebLogo} alt="Web" />
        </a>
      </div>
    </div>
  );
};

export default GameOverPage;
