import { styled } from '@material-ui/core';
import React from 'react';

export const ResumeButton = styled('button')({
  background: '#de9520',
  border: '5px solid #795110',
  color: 'black',
  padding: '15px 32px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '4px 2px',
  cursor: 'pointer',
});
