import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ResumeButton } from './ResumeBtn';
import HorizontalImage from '../../../assets/images/horizontal.png';

export const useStyles = makeStyles({
  centered: {
    background: '#de9520',
    padding: '50px',
    position: 'absolute',
    top: 'auto',
    left: 'auto',
    fontFamily: 'Arial, Helvetica, sans-serif',
    color: 'black',
    fontSize: '12px',
    verticalAlign: 'middle',
    width: '100vw',
    border: '5px solid #795110',
  },
  dumbImage: {
    marginTop: '200px',
    width: '375px',
    height: '300px',
  },
});

export default function IPhoneOrientationMessage() {
  const classes = useStyles();

  const scrollToBottom = () => {
    // if (osName !== 'iOS') {
    //   return;
    // }
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <div id="warning-message">
      <ResumeButton onClick={scrollToBottom}>Resume </ResumeButton>
      <div style={{ position: 'absolute', top: '100vh', height: 'auto' }}>
        <figcaption className={classes.centered}>
          We strongly recommend that you play this game on a desktop/ laptop for
          the best experience. You might occasionally face some issues while
          playing on your iPhone. If you want to continue on your iPhone, please
          hold your phone in the horizontal/ landscape position.
        </figcaption>
        <img src={HorizontalImage} className={classes.dumbImage} alt="" />
      </div>
    </div>
  );
}
