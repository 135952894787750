import { useEffect } from 'react';
import { setFullScreen } from '../app/games/iFrameGameMaster/utils/orientationAndFullscreen';

export const useSetFullScreen = () => {
  useEffect(() => {
    setFullScreen()
      .then(() => {
        console.log('Full Screen Successful');
      })
      .catch((err) => {
        console.log(err);
      });
    const handleTouchStart = async () => {
      try {
        // scrollBottom();
        await setFullScreen();
      } catch (error) {
        console.log(error.message);
      }
    };

    window.addEventListener('touchend', handleTouchStart);

    return () => {
      window.removeEventListener('touchend', handleTouchStart);
    };
  }, []);
};
