import { getDeviceInfo } from '../../../../hooks/useDeviceInfo2';
import { baseGameUrl, urlsStart } from '../../../constants';

// https://games.talentlitmus.com/marioweb
// https://games.talentlitmus.com/mariomobile

export const getGameLinkType = (gameType = ''): 'mobile' | 'web' | 'lowres' => {
  const osName = getDeviceInfo();
  let linkType: 'mobile' | 'web' | 'lowres' = 'mobile';
  if (osName === 'Android' || osName === 'iOS' || window.innerHeight <= 768) {
    if (
      gameType === 'MarioGame' &&
      osName === 'desktop' &&
      window.innerHeight <= 768
    ) {
      linkType = 'lowres';
    } else {
      linkType = 'mobile';
    }
  } else {
    linkType = 'web';
  }

  return linkType;
};

export const getGameUrl = (gameId, gameType, osName, gameName = '') => {
  let linkType = getGameLinkType(gameType);
  //TODO: Remove this, temp for now until web version are uploaded
  // linkType = 'mobile';

  if (gameType === 'MiniGames') {
    if (gameName === 'Leaderboard') {
      return `https://games.talentlitmus.com/leaderboard${linkType}?myParam=${gameId}`;
    }

    if (gameName === 'Orientation Packing Game') {
      return `https://demo.talentlitmus.com/orientationpacking${linkType}?myParam=${gameId}`;
    }
    if (gameName === 'Orientation Map Game') {
      return `https://games.talentlitmus.com/mapgame${linkType}?myParam=${gameId}`;
    }
    //TODO handle the case of endless runner - for it we want link type to be of mobile

    // if (gameId === '6062b96d816ac300145a901b') {
    //   //! This is a temp code for testing, we will manually fire game loaded event
    //   window.top.postMessage(
    //     JSON.stringify({
    //       gameId,
    //       eventType: 'GameLoaded',
    //     }),
    //     '*'
    //   );

    //   return 'https://demo.talentlitmus.com/endlessrunner3d/';
    // }
  }

  return `${baseGameUrl}/${urlsStart[gameType]}${linkType}?myParam=${gameId}`;
};

export const getVideoDimensions = () => {
  let iHeight = window.innerHeight > 720 ? 720 : window.innerHeight;
  let iWidth = window.innerWidth > 1280 ? 1280 : window.innerWidth;
  let paddingTop =
    window.innerWidth > 1280 || window.innerHeight > 720 ? '1.67em' : null;

  const deviceType = getGameLinkType();
  if (deviceType === 'mobile') {
    iHeight = window.innerHeight;
    iWidth = window.innerWidth;
    paddingTop = null;
  }

  return { iHeight, iWidth, paddingTop };
};
