import React, { useEffect, useState } from 'react';
import { useGetToken } from '../../hooks/useGetToken';

import useGameStore from '../../store/useGameStore';
import gameDataAPI from '../api/gameDataAPI';
import LoaderCircular from '../components/shared/ui/Loader';
import CompanyLogo from './CompanyLogo';
import ErrorPage from './pages-ui/TokenError';
import TokenInputPage from './pages-ui/TokenInputPage';

export default function TokenAuth({ children }) {
  const token = useGetToken();
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const setUserData = useGameStore((state) => state.setUserData);
  const setMusicUrls = useGameStore((state) => state.setMusicUrls);
  const setGameToken = useGameStore((state) => state.setGameToken);
  const user = useGameStore((state) => state.userData);
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        setShowLogo(false);
      }, 5 * 1000);
    }
  }, [user]);

  const submitToken = (e, token) => {
    e.preventDefault();
    setError(null);
    gameDataAPI
      .fetchUserData(token)
      .then((userData) => {
        console.log('User data', userData);

        gameDataAPI
          .getMusicAssets(userData.gameDetails._id)
          .then((musicUrls) => {
            console.log(musicUrls);
            setMusicUrls(musicUrls);

            // we are setting user data after we have fetched the music urls
            // because without music url we don't want to load the game

            setUserData(userData);

            setError(null);
            setGameToken(token);
          })
          .catch((err) => {
            console.log(err);
            setUserData(userData);
            setMusicUrls([]);
            setError(null);
            setGameToken(token);
          });
      })
      .catch((error) => {
        console.log(error);
        setShowError(true);
        setError('No token found');
      });
  };

  useEffect(() => {
    if (!token) {
      setError('No token found');

      return;
    }

    gameDataAPI
      .fetchUserData(token)
      .then((userData) => {
        console.log('User data', userData);

        gameDataAPI
          .getMusicAssets(userData.gameDetails._id)
          .then((musicUrls) => {
            console.log(musicUrls);
            setMusicUrls(musicUrls);

            // we are setting user data after we have fetched the music urls
            // because without music url we don't want to load the game

            setUserData(userData);
            setGameToken(token);
          })
          .catch((err) => {
            console.log(err);
            setUserData(userData);
            setMusicUrls([]);
            setError(null);
            setGameToken(token);
          });
      })
      .catch((error) => {
        console.log(error);
        setError('Invalid Token');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error === 'No token found') {
    return <TokenInputPage onFormSubmit={submitToken} showError={showError} />;
  }

  if (error) {
    return <ErrorPage text={error} />;
  }
  if (!user) {
    return <LoaderCircular type={1} />;
  }

  if (showLogo && user.gameDetails.companyId.company_logo) {
    return <CompanyLogo logo={user.gameDetails.companyId.company_logo} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}
