import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import DynamicContainer from '../shared/ui/DynamicContainer';
import RedButtonSecondary from '../shared/ui/RedButtonSecondary';

const useStyles = makeStyles({
  container: {
    // marginTop: '10%',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '80%',
    // backgroundColor:'black'
  },
  text: {
    fontSize: (props: any) => props.media ?'1.5rem' :'1.8rem',
    color: 'white',
    textAlign: 'center',
    lineHeight: '1.8rem',
    
  
    
    // marginBottom: '30px',
  },
  btn: {
    fontSize:(props: any) => props.media ? '1.2rem': '1.4rem',
    width: '200px',
    height: '70px',
    marginBottom: '15px',
    
  },
});

const VRminiGame = ({ onCompleted }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  
  const classes = useStyles({media:matches});

  return (
    <DynamicContainer style_content={{background:'black'}} >
      <div className={classes.container}>
        <p className={classes.text}>
          Click on the button below to open a new tab where you can go on a
          Virtual Reality tour of a factory of Leminar Air Conditioning
          Industries LLC. Use the password 1971 to start the tour. Once you have
          completed the tour, you can come back to this tab to continue the
          game.
        </p>
        <RedButtonSecondary
          className={classes.btn}
        >
          <a
            style={{ textDecoration: 'none', color: 'white' }}
            href="https://360emirates.com/dubai/360-virtual-tour/Leminar-Air-Conditioning-Industries-LLC/"
            target="_blank"
            rel="noreferrer"
          >
            Start VR Tour
          </a>
        </RedButtonSecondary>
        <RedButtonSecondary
          className={classes.btn}
          onClick={() => onCompleted('Hi')}
        >
          Continue Game
        </RedButtonSecondary>
      </div>
    </DynamicContainer>
  );
};

export default VRminiGame;
