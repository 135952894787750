import { makeStyles } from '@material-ui/core';
import React from 'react';
import BtnRedBg from '../../../assets/images/ButtonRed3.png';

const useStyles = makeStyles({
  btn: {
    boxSizing: 'border-box',
    appearance: 'none',
    // backgroundColor: 'transparent',
    background: `url(${BtnRedBg})`,
    // backgroundSize: 'cover',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    letterSpacing:'1px',
    // border: '2px solid #e74c3c',
    border: 'none',
    borderRadius: '0.6em',
    color: (props: any) => (props.disabled ? '#494949' : '#dbdbdb'),
    cursor: (props: any) => (props.disabled ? 'default' : 'pointer'),
    display: 'flex',
    alignSelf: 'center',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    lineHeight: 1,
    margin: '12px',
    padding: '1em 1.5em',
    textDecoration: 'none',
    textAlign: 'center',
    // textTransform: 'uppercase',
    fontFamily: "'Montserrat', sans-serif",
    width: '335px',

    '&:hover, &:focus': {
      color: (props: any) => (props.disabled ? '#494949' : '#fff'),
      outline: 0,
    },
  },

  first: {
    // transition: 'box-shadow 300ms ease-in-out, color 300ms ease-in-out',
    // '&:hover': {
    //   boxShadow: '0 0 40px 40px #e74c3c inset',
    // },
  },
});

export default function ButtonFirst({ children, ...rest }) {
  const classes = useStyles({ disabled: rest.disabled });

  return (
    <button className={`${classes.btn} ${classes.first}`} {...rest}>
      <span style={{ textAlign: 'center', width: '100%' }}>{children}</span>
    </button>
  );
}
