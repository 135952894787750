import { Fragment, useEffect, useRef, useState } from 'react';

import gameDataAPI from '../../api/gameDataAPI';

import LoaderCircular from '../shared/ui/Loader';
import { makeStyles } from '@material-ui/core';
import { getGameLinkType, getVideoDimensions } from '../../games/iFrameGameMaster/utils/gameUtils';
import Player from '@vimeo/player';
import { VideoContainer } from './styles/VideoContainer';
import { getScreenOrientation } from '../../games/iFrameGameMaster/utils/orientationAndFullscreen';


const useStyles = makeStyles({
  videoIframe: {
    border: 'none',
  },
});



export default function VimeoPlayer({ gameId, onComplete, osName }) {
  const classes = useStyles();

  const playerRef = useRef<Player>();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const screenOrientation = getScreenOrientation();
  const [showResumeScreen, setShowResumeScreen] = useState(
    osName === 'iOS' && screenOrientation === 'portrait'
  );
  const { iHeight, iWidth, paddingTop } = getVideoDimensions();
  const [dimensions, setDimensions] = useState({
    height: iHeight,
    width: iWidth,
  });

  const [videoData, setVideoData] = useState({
    isLoaded: false,
    data: null,
    videoId: '',
  });

  // TODO refractor common code of the first video and this Vimeo Player
  useEffect(() => {
    const getVideoData = async () => {
      const data = await gameDataAPI.fetchVideoData(gameId);
      // url format = https://vimeo.com/532650697/b43f5c6023
      const videoId = data.url.split('/')[3];
      setVideoData({ isLoaded: true, data, videoId });
    };

    getVideoData();

    const onOrientationChange = () => {
      setTimeout(() => {
        if (!iframeRef.current) return;

        const { iHeight, iWidth, paddingTop } = getVideoDimensions();

        // iframeRef.current.width = iHeight.toString();
        // iframeRef.current.height = iWidth.toString();
        setDimensions({height: iHeight, width: iWidth});
      }, 300);

      const o = getScreenOrientation();

      if (osName === 'iOS' && o === 'portrait') {
        setShowResumeScreen(true);
        if (playerRef.current) playerRef.current.pause();
      } else if (osName === 'iOS' && o === 'landscape') {
        setShowResumeScreen(false);
        if (playerRef.current) playerRef.current.play();
      }
    };

    window.addEventListener('orientationchange', onOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, [gameId]);

  useEffect(() => {
    if (!videoData.isLoaded) return;

    //TODO: Error handling

    const player = new Player(iframeRef.current);
    playerRef.current = player;
    player
      .play()
      .then(() => {
        console.log('Video playing');
      })
      .catch((err) => {
        console.warn(err);
      });

    player.on('ended', () => {
      onComplete();
    });

    return () => {
      player.off('ended');
    };
  }, [videoData, onComplete]);

  if (!videoData.isLoaded) {
    return <LoaderCircular />;
  }



  return (
    <Fragment>
    
      <VideoContainer
        style={{
          display: showResumeScreen ? 'none' : 'flex',
          justifyContent: 'center',
          paddingTop: paddingTop,
        }}
      >
        {/* @ts-ignore */}

        <iframe
          className={classes.videoIframe}
          ref={iframeRef}
          src={`https://player.vimeo.com/video/${videoData.videoId}`}
          width={dimensions.width}
          height={dimensions.height}
          allow="autoplay; fullscreen; picture-in-picture"
          title="Talent Litmus Music"
        ></iframe>
      </VideoContainer>
    </Fragment>
  );
}

/* 
    onReady={() => {
          setVideoData((state) => ({ ...state, isLoaded: true }));
        }}
        
        https://vimeo.com/532650697/b43f5c6023
*/
