import { makeStyles } from '@material-ui/core';
import HeroBackGround from './../../../assets/images/BG_00224.jpg';
export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    height: '100vh',
    background: `url(${HeroBackGround})`,
  },
  iframe: {
    // display: (props: any) => (props.display ? 'block' : 'none'),
    visibility: (props: any) => (props.display ? 'visible' : 'hidden'),
    display: 'block',
    height:  (props: any) => (props.display ? '100vh':'1vh'),
    width:  (props: any) => (props.display ? '100vw' :'1vw'),
    margin: '0',
    padding: '0',
    border: 'none',
    overflow: 'hidden',
  },
}));
