import React, { useEffect, useState } from 'react';
import { enableMapSet } from 'immer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { GlobalContextProvider } from './contexts/GlobalContext';
import { useInitialLoader } from './hooks/useInitialLoader';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './shared/Theme';
import { useDeviceInfo } from './hooks/useDeviceInfo';
import TokenAuth from './app/pages/TokenAuth';
import Home from './app/pages/Home';
import { useSetFullScreen } from './hooks/useSetFullScreen';
import SoundElements from './app/components/soundElements/SoundElements';


enableMapSet();

function App() {
  useInitialLoader();
  useDeviceInfo();
  useSetFullScreen();

  return (
    <React.Fragment>
      <GlobalContextProvider>
        <ThemeProvider theme={theme}>
          <div>
            <ToastContainer />
            <TokenAuth>
              <Home />
              <SoundElements />
            </TokenAuth>
          </div>
        </ThemeProvider>
      </GlobalContextProvider>
    </React.Fragment>
  );
}

export default App;

/* 
<DownloadFile gameId={"6218f4643422de00157d867a"} onComplete={() => alert('complete')}/>
    <TokenAuth>
              <Home />
              <SoundElements />
            </TokenAuth>
*/
