import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CloseButton } from '../shared/ui/CloseBtn';
import gameDataAPI from '../../api/gameDataAPI';
import RedButtonSecondary from '../shared/ui/RedButtonSecondary';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  },
  box: {
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
  },
  textContent: {
    color: 'white',
    fontSize: '30px',
    textAlign: 'center',
  },
  btn: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '28px',
  },
});

const DownloadFile = ({ gameId, onCompleted }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState('');
  const classes = useStyles();

  function download() {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileUrl.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useEffect(() => {
    setIsLoading(true);

    const loadFileUrl = async () => {
      try {
        const data = await gameDataAPI.getFileUrl(gameId);
        console.log(data);
        setFileUrl(data.url);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setFileUrl('error');
      }
    };

    // document.body.style.overflowY = 'scroll';

    loadFileUrl();
  }, [gameId]);

  return (
    <div>
      {' '}
      <CloseButton
        style={{ position: 'absolute', top: '5%', right: '5%' }}
        variant="contained"
        onClick={onCompleted}
      >
        Next
      </CloseButton>
      <div className={classes.container}>
        <div className={classes.box}>
          <div className={classes.textContent}>
            You have reached the end of this episode. You can download files for
            this episode by clicking below.
          </div>

          <RedButtonSecondary
            onClick={() => download()}
            style={{ minWidth: '200px', minHeight: '60px' }}
            className={classes.btn}
          >
            Download
          </RedButtonSecondary>
        </div>
      </div>
    </div>
  );
};

export default DownloadFile;
