import { makeStyles } from '@material-ui/core';
import React from 'react';
import DynamicContainer from '../components/shared/ui/DynamicContainer';

import {
  getGameLinkType,
  getVideoDimensions,
} from '../games/iFrameGameMaster/utils/gameUtils';

let { iHeight, iWidth, paddingTop } = getVideoDimensions();

const useStyles = makeStyles({
  image: {
    height: '50%',
    maxWidth: '100%',
  },
});

const logoBaseURL = 'https://talent-litmus.s3.ap-south-1.amazonaws.com/';

export default function CompanyLogo({ logo }) {
  console.log(logo);
  const companyLogo = logoBaseURL + logo;
  const classes = useStyles();

  return (
    <DynamicContainer>
      <img className={classes.image} src={companyLogo} alt="CompanyLogo" />
    </DynamicContainer>
  );
}
