import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoaderImageLight from './../../../../assets/images/lf1.jpg';
import LoaderImageDark from './../../../../assets/images/lf2.jpg';
import { getVideoDimensions } from '../../../games/iFrameGameMaster/utils/gameUtils';


const { iHeight, iWidth, paddingTop } = getVideoDimensions();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderContainer: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: paddingTop,
    },

    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: iHeight.toString() + 'px',
      width: iWidth.toString() + 'px',
      backgroundSize: '100% 100% !important',
      // backgroundColor: '#EEEEFF',
      background: (props: any) =>
        props.type === 1
          ? `url(${LoaderImageLight})`
          : `url(${LoaderImageDark})`,

      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

export default function LoaderCircular({ type = 2 }) {
  const classes = useStyles({ type });

  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loader}></div>;
    </div>
  );
}

/*
  <CircularProgress size="4rem" />

 */
