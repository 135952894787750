import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  getGameLinkType,
  getVideoDimensions,
} from '../../../games/iFrameGameMaster/utils/gameUtils';

let { iHeight, iWidth, paddingTop } = getVideoDimensions();

const useStyles = makeStyles({
  container: {
    backgroundColor: 'black',
    display: 'flex',
    // alignItems: 'center',
    paddingTop: paddingTop,
    justifyContent: 'center',
    overflow: 'none',
    width: '100vw',
    height: '100vh',
    // height: '100vh'F
  },
  content: {
    color: 'white',
    background: 'white',
    height: (props: any) => (props.type === 'web' ? iHeight : '100vh'),
    width: (props: any) =>
      props.type === 'web' ? iWidth : 'calc(100vh*1.777)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: '50%',
    maxWidth: '100%',
  },
});

const DynamicContainer = ({
  children,
  style_container = {},
  style_content = {},
}) => {
  const gameLinkType = getGameLinkType();
  const classes = useStyles({ type: gameLinkType });

  return (
    <div className={classes.container} style={style_container}>
      <div className={classes.content} style={style_content}>
        {children}
      </div>
    </div>
  );
};

export default DynamicContainer;
