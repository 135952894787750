import {
  Backdrop,
  Button,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import gameDataAPI from '../../api/gameDataAPI';
import LoaderCircular from '../shared/ui/Loader';
import PdfBgImage from '../../../assets/images/HomeLaptopblurred.jpg';
import { CloseButton } from '../shared/ui/CloseBtn';
import { getDeviceInfo } from '../../../hooks/useDeviceInfo2';
import { getScreenOrientation } from '../../games/iFrameGameMaster/utils/orientationAndFullscreen';
import PdfDownload from './pdfDownload';


const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const useStyles = makeStyles((theme) => ({
  example: {
    // backgroundColor: 'chocolate',
    background: `url(${PdfBgImage})`,
  },
  exampleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px 0',
    padding: '10px 0',
    minHeight: '100vh',
    // maxWidth:'100vw'
  },
  exampleContainerLoad: {
    marginTop: '1em',
    color: 'white',
  },
  exampleContainerDocument: {
    margin: '1em 0',
    // overflowY:'initial'
  },

  nextButton: {
    position: 'fixed',
    top: '20px',
    right: '2%',
    zIndex: 999,
    fontSize: '0.80rem',
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function PdfView({ gameId, onComplete }) {
  const classes = useStyles();
  // const [loading, setLoading] = useState(true);
  //   const [file, setFile] = useState(filePath);
  const [numPages, setNumPages] = useState(null);
  const [fileUrl, setFileUrl] = useState('');

  const [pageSize, setPageSize] = useState(window.innerWidth * 0.9);
  const osName = getDeviceInfo();
  const orientation = getScreenOrientation();
  const [display, setDisplay] = useState('block');
  const [pagesRendered, setPagesRendered] = useState(0);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  //   function onFileChange(event) {
  //     setFile(event.target.files[0]);
  //   }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    // reset the data to initial state when gameId changes
    setNumPages(null);
    setFileUrl('');
    setPagesRendered(0);
    setIsDocumentLoaded(false);

    const loadPdfData = async () => {
      try {
        const data = await gameDataAPI.fetchPdfData(gameId);
        setFileUrl(data.url);
      } catch (error) {
        console.error(error);
        setFileUrl('error');
      }
    };

    // document.body.style.overflowY = 'scroll';

    loadPdfData();

    return () => {
      document.body.style.overflowY = 'initial';
    };
  }, [gameId]);

  useEffect(() => {

    const onOrientationChange = () => {
      if (osName === 'iOS' && getScreenOrientation() === 'portrait') {
        setDisplay('none')
      } else {
        setDisplay('block');
      }
    }

    window.addEventListener('resize', onOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, []);

  if (fileUrl === '') {
    return <LoaderCircular />;
  }
  if (fileUrl === 'error') {
    return <h1>Error loading pdf file data</h1>;
  }

  const onRenderSuccess = () => {
    setPagesRendered((prev) => prev + 1);
    if (!isDocumentLoaded) {
      setIsDocumentLoaded(true);
    }
  };

  const onRenderComplete = () => {
    if (!isDocumentLoaded) {
      setIsDocumentLoaded(true);
    }
  }

  const pageRenderedPlusOne = Math.min(pagesRendered + 1, numPages);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={!isDocumentLoaded}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={classes.example} style={{ display }}>
       <PdfDownload fileUrl={fileUrl}/>
        <div className={classes.exampleContainer}>
          <div className={classes.exampleContainerDocument}>
            <CloseButton variant="contained" onClick={onComplete}>
              Close
            </CloseButton>
            <Document
              renderMode="canvas"
              file={fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              {Array.from(new Array(pageRenderedPlusOne), (el, index) => {
                const isCurrentlyRendering = pageRenderedPlusOne === index + 1;
                const isLastPage = numPages === index + 1;
                const needsCallbackToRenderNextPage =
                  isCurrentlyRendering && !isLastPage;

                return (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={pageSize}
                    onRenderSuccess={
                      needsCallbackToRenderNextPage ? onRenderSuccess : onRenderComplete
                    }
                  />
                );
              })}
            </Document>
          </div>
        </div>
      </div>
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  // true if we don't want to rerender
  return prevProps.gameId === nextProps.gameId;
}

export default PdfView;


/* 
   <iframe className={classes.iframeContent}
            title="asd"
            src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true&view=fith`}
           
          ></iframe>


      <Document
            renderMode="svg"
            file={fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(pageRenderedPlusOne), (el, index) => {
              const isCurrentlyRendering = pageRenderedPlusOne === index + 1;
              const isLastPage = numPages === index + 1;
              const needsCallbackToRenderNextPage =
                isCurrentlyRendering && !isLastPage;

              return (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={window.innerWidth * 0.9}
                  onRenderSuccess={
                    needsCallbackToRenderNextPage ? onRenderSuccess : null
                  }
                />
              );
            })}
          </Document>

          
           <div className={classes.example}>
      <div className={classes.exampleContainer}>
        <div className={classes.exampleContainerDocument}>
          <Button
            variant="contained"
            className={classes.nextButton}
            onClick={onComplete}
          >
            Next
          </Button>
          <iframe className={classes.iframeContent}
            title="asd"
            src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true&view=fith`}
           
          ></iframe>
        </div>
      </div>
    </div>
          
          
*/
